import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { BsArrowUp, BsSearch } from "react-icons/bs";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleCurrentPage } from "../../features/page/pageSlice";

export default () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [mobile] = useMediaQuery("(max-width: 480px)");

  const [isNoTransactionsModalOpen, setIsNoTransactionsModalOpen] =
    useState(false);

  const handleCloseNoTransactionsModal = () => {
    setIsNoTransactionsModalOpen(false);
    dispatch(handleCurrentPage("/"));
    navigate("/dashboard");
  };

  return (
    <Flex w="100%">
      <NoTransactionsModal
        isNoTransactionsModalOpen={isNoTransactionsModalOpen}
        handleCloseNoTransactionsModal={handleCloseNoTransactionsModal}
      />

      <Box w={{ base: "100%", md: "calc(100% - 24rem)" }}>
        <VStack
          py={8}
          px={5}
          alignItems="flex-start"
          spacing={8}
          w={{ base: "100%", md: "md" }}
        >
          <HStack spacing={3}>
            <Text display={{ base: "none", md: "block" }}>Dashboard</Text>
            <Box display={{ base: "none", md: "block" }}>
              <MdOutlineArrowForwardIos size={12} />
            </Box>
            {mobile ? (
              <Heading fontSize="1.1rem" fontWeight="600">
                All Transactions
              </Heading>
            ) : (
              <Text fontWeight="600">All transactions</Text>
            )}
          </HStack>
          <HStack w="100%" alignItems="flex-end" justifyContent="space-between">
            <FormControl w={{ base: "40%", md: "45%" }}>
              <FormLabel color="dark.100">From date</FormLabel>
              <Input defaultValue="2021-01-01" type="date" color="dark.100" />
            </FormControl>
            <FormControl w={{ base: "40%", md: "45%" }}>
              <FormLabel color="dark.100">To date</FormLabel>
              <Input defaultValue="2021-01-01" type="date" color="dark.100" />
            </FormControl>
            <IconButton aria-label="Search" variant="secondary">
              <BsSearch />
            </IconButton>
          </HStack>
          <Heading
            fontSize="1.1rem"
            fontWeight="600"
            display={{ base: "none", md: "block" }}
          >
            All Transactions
          </Heading>
          <VStack w="100%">
            <Heading
              w="100%"
              fontSize="1.1rem"
              fontWeight="600"
              color="dark.100"
            >
              TODAY
            </Heading>
            <TransactionCard />
            <TransactionCard />
          </VStack>
          <VStack w="100%">
            <Heading
              w="100%"
              fontSize="1.1rem"
              fontWeight="600"
              color="dark.100"
            >
              YESTERDAY
            </Heading>
            <TransactionCard />
            <TransactionCard />
          </VStack>
          <VStack w="100%">
            <Heading
              w="100%"
              fontSize="1.1rem"
              fontWeight="600"
              color="dark.100"
            >
              01 JANUARY, 2023
            </Heading>
            <TransactionCard />
            <TransactionCard />
          </VStack>
          <Box w="70%" py={8} alignSelf="center">
            <Button
              w="100%"
              size="lg"
              variant="outline"
              color="darkblue.300"
              borderColor="darkblue.300"
            >
              Load more
            </Button>
          </Box>
        </VStack>
      </Box>
      <VStack
        w="24rem"
        spacing={16}
        py={24}
        px={2}
        display={{ base: "none", md: "flex" }}
        borderLeftWidth="1px"
        borderColor="gray.300"
      >
        <Image src="/investment-live-opportunities.png" w="100%" />
        <VStack spacing={5}>
          <Heading w="100%" fontSize="1rem">
            WATCH HOW TO USE SALVAPAY
          </Heading>
          <Image src="/how-salvapay-works.png" w="100%" />
        </VStack>
        <VStack spacing={5} w="100%">
          <Heading w="100%" fontSize="1rem" textAlign="center">
            DOWNLOAD OUR APP FROM
          </Heading>
          <HStack>
            <Button py={5} px={2} colorScheme="none" color="white" bg="black">
              <HStack>
                <Image src="/playstore.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">GET IT ON</Text>
                  <Heading fontSize="1rem">Google Play</Heading>
                </VStack>
              </HStack>
            </Button>
            <Button py={5} px={2} variant="outline" borderColor="black">
              <HStack>
                <Image src="/apple-logo.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">Download on the</Text>
                  <Heading fontSize="1rem">App Store</Heading>
                </VStack>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  );
};

export const TransactionCard = () => {
  return (
    <HStack w="100%" spacing={3}>
      <Center bg="error.50" color="error.300" p={3} borderRadius={5}>
        <BsArrowUp />
      </Center>
      <VStack spacing={0} alignItems="flex-start">
        <Text>Write Famous</Text>
        <Text color="dark.100">As discussed initially...</Text>
      </VStack>
      <Spacer />
      <VStack alignItems="flex-end">
        <Text color="error.300">£1,000</Text>
        <Text color="dark.100">Jun 10</Text>
      </VStack>
    </HStack>
  );
};

const NoTransactionsModal = ({
  handleCloseNoTransactionsModal,
  isNoTransactionsModalOpen,
}: {
  handleCloseNoTransactionsModal: () => void;
  isNoTransactionsModalOpen: boolean;
}) => {
  return (
    <Modal
      onClose={handleCloseNoTransactionsModal}
      isOpen={isNoTransactionsModalOpen}
      motionPreset="slideInBottom"
      isCentered
      size="sm"
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <ModalCloseButton color="error.300" size="sm" />
          <VStack spacing={6}>
            <Heading fontSize="1.5rem" fontWeight="600">
              No transactions added!
            </Heading>
            <Text color="blackAlpha.800" textAlign="center">
              You have not performed any transaction yet.
            </Text>
            <Button
              w="80%"
              size="lg"
              variant="secondary"
              onClick={handleCloseNoTransactionsModal}
            >
              Go to dashboard
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
