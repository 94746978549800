import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleToggleHeaderDropdown } from "../features/header/headerSlice";
import { LogoutModal } from "../pages/dashboard/DashboardWrapper";
import {
  handleCloseMainModal,
  handleMainModalToRender,
  handleOpenMainModal,
  handleToggleLogoutDialog,
} from "../features/modals/modalSlice";

const Dropdown = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { authenticated } = useSelector((state: RootState) => state.auth);
  const { isLogoutDialogActive } = useSelector(
    (state: RootState) => state.modal
  );

  return authenticated ? (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: "auto",
          opacity: 1,
          transition: {
            height: {
              duration: 0.25,
              delay: 0.15,
            },
            opacity: {
              duration: 0.25,
              delay: 0.15,
            },
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
            },
          },
        }}
      >
        <Box h="100vh" w="100%" bg="blackAlpha.50" pos="absolute" inset={0} />
        <Box
          pos="absolute"
          zIndex={50}
          top="100%"
          w="100%"
          h="fit-content"
          left={0}
          right={0}
          bg="white"
        >
          <VStack spacing={5} p={5} w="100%" alignItems="left">
            <NavigationButton link="/dashboard" title="Dashboard" />
            <NavigationButton
              link="/dashboard/transactions"
              title="All transactions"
            />
            <NavigationButton link="/dashboard/send" title="Send money" />
            <NavigationButton link="/dashboard/fund" title="Fund wallet" />
            <NavigationButton link="/dashboard/request" title="Receive money" />
            <NavigationButton
              link="/dashboard/settings"
              title="My profile"
              action={() => {
                dispatch(handleToggleHeaderDropdown());
                dispatch(handleMainModalToRender("Settings"));
                dispatch(handleOpenMainModal());
              }}
            />
            <NavigationButton
              link="/dashboard/"
              title="Notifications"
              action={() => {
                dispatch(handleToggleHeaderDropdown());
                dispatch(handleMainModalToRender("Notifications"));
                dispatch(handleOpenMainModal());
              }}
            />
            <Button
              variant="link"
              color="error.300"
              textAlign="left"
              onClick={() => {
                dispatch(handleToggleHeaderDropdown());
                dispatch(handleToggleLogoutDialog());
              }}
            >
              <Text w="100%">Logout</Text>
            </Button>
          </VStack>
        </Box>
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: "auto",
          opacity: 1,
          transition: {
            height: {
              duration: 0.25,
              delay: 0.15,
            },
            opacity: {
              duration: 0.25,
              delay: 0.15,
            },
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
            },
          },
        }}
      >
        <Box h="100vh" w="100%" bg="blackAlpha.50" pos="absolute" inset={0} />
        <Box
          pos="absolute"
          zIndex={50}
          top="100%"
          w="100%"
          h="fit-content"
          left={0}
          right={0}
          bg="white"
        >
          <VStack spacing={5} p={5} w="100%" alignItems="left">
            <Link href="/">Home</Link>
            <Link href="/">Individual</Link>
            <Link href="/">Business</Link>
            <Link href="/faqs">Need help</Link>
            <Link href="/login">Login</Link>
          </VStack>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

const NavigationButton = ({ action, link, title }: NavigationButton) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Button
      variant="link"
      textAlign="left"
      color="dark.500"
      onClick={
        action
          ? action
          : () => {
              dispatch(handleToggleHeaderDropdown());
              dispatch(handleCloseMainModal());
              navigate(link);
            }
      }
    >
      <Text w="100%">{title}</Text>
    </Button>
  );
};

export default () => {
  const [mobile] = useMediaQuery("(max-width: 480px)");
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state: RootState) => state.header);

  const handleToggle = () => {
    dispatch(handleToggleHeaderDropdown());
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isOpen]);

  return mobile ? (
    <Box w="100%">
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: "auto",
          opacity: 1,
          transition: {
            height: {
              duration: 0.25,
              delay: 0.15,
            },
            opacity: {
              duration: 0.25,
              delay: 0.15,
            },
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
            },
          },
        }}
      >
        <Flex
          px={5}
          py={5}
          justifyContent="space-between"
          borderBottomWidth="1px"
          borderColor="purple.100"
          w="100%"
          alignItems="center"
          pos="sticky"
          top={0}
          bg="white"
          zIndex={100}
        >
          {isOpen ? <Dropdown /> : null}
          <Image src="/salvapay-icon.svg" />
          <Spacer />
          <IconButton
            onClick={handleToggle}
            icon={<FiMenu size={30} />}
            aria-label="Menu"
            bg="none"
          />
        </Flex>
      </motion.div>
    </Box>
  ) : (
    <Box w="100%">
      <Flex
        mx="auto"
        px={14}
        py={5}
        justifyContent="space-between"
        w="100%"
        maxW="80rem"
      >
        <Image src="/salvapay-icon.svg" />
        <HStack w="md" spacing={8}>
          <NavLink props={{ title: "Home", link: "/", active: true }} />
          <NavLink props={{ title: "Individual", link: "/", active: false }} />
          <NavLink props={{ title: "Business", link: "/", active: false }} />
          <NavLink
            props={{ title: "Need help?", link: "/faqs", active: false }}
          />
        </HStack>
        <HStack>
          <Button
            colorScheme="none"
            color="purple.800"
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
          <Button variant="secondary">Download App</Button>
        </HStack>
      </Flex>
    </Box>
  );
};

interface Props {
  props: {
    title: string;
    link: string;
    active: boolean;
  };
}

const NavLink = ({ props }: Props) => {
  const { active, link, title } = props;

  return (
    <Link
      _hover={{ textDecor: "none" }}
      href={link}
      color={active ? "purple.900" : "blackAlpha.700"}
      fontWeight="600"
    >
      {title}
    </Link>
  );
};
