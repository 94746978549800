import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";

export default () => {
  return (
    <VStack spacing={5} w={{ base: "100%", md: "xs" }}>
      <Heading
        w="100%"
        fontSize="1.1rem"
        fontWeight="600"
        display={{ base: "block", md: "none" }}
      >
        Update KYC
      </Heading>
      <Avatar name="John Doe" size="md" src="https://bit.ly/ryan-florence" />
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={1}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Registered address
        </FormLabel>
        <Input w="100%" h="3rem" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={1}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Post code
        </FormLabel>
        <Input w="100%" h="3rem" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={1}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Means of ID
        </FormLabel>
        <Select>
          <option>International passport</option>
        </Select>
      </FormControl>
      <Box pos="relative" w="100%">
        <Image src="/kyc-placeholder.png" w="100%" />
        <Button
          pos="absolute"
          bottom={0}
          w="100%"
          h="3rem"
          bg="blackAlpha.200"
          color="darkblue.300"
        >
          TAP TO CHANGE
        </Button>
      </Box>
      <Button w="80%" h="3rem" variant="secondary">
        Continue
      </Button>
    </VStack>
  );
};
