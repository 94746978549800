import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";
import { CardInfo } from "../../profile/Card";
import AddCardFeedbackModal from "../feedback/AddCardFeedbackModal";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate("/dashboard")}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const [success, setSuccess] = useState<boolean | undefined>();

  return (
    <MobileModalWrapper>
      <VStack
        w="100%"
        spacing={8}
        py={{ base: 5, md: 0 }}
        px={{ base: 5, md: 0 }}
      >
        <Text w="100%" fontWeight="600">
          My Profile
        </Text>
        <NavigationButton link="/dashboard/settings" title="Profile settings" />
        <NavigationButton link="/dashboard/settings/kyc" title="Update KYC" />
        <NavigationButton
          link="/dashboard/settings/security"
          title="Security settings"
        />
        <NavigationButton
          link="/dashboard/settings/card"
          title="Card settings"
        />
        <NavigationButton
          link="/dashboard/settings/contact"
          title="Contact salvapay"
        />
      </VStack>
    </MobileModalWrapper>
  );
};

const NavigationButton = ({ link, title }: { link: string; title: string }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Button
      w="100%"
      p={6}
      variant="outline"
      onClick={() => {
        dispatch(handleCloseMainModal());
        navigate(link);
      }}
    >
      <HStack w="100%">
        <Text>{title}</Text>
        <Spacer />
        <MdOutlineArrowForwardIos />
      </HStack>
    </Button>
  );
};
