import {
  Button,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";

export default ({
  type,
  handleCloseModal,
}: {
  type: string;
  handleCloseModal: () => void;
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      onClose={() => dispatch(handleCloseMainModal())}
      isOpen={true}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6}>
            <Image src="/credit-card-outline.png" alt="Credit card" />
            <Heading fontSize="1.3rem" fontWeight="600" color="red">
              Remove this {type}?
            </Heading>
            <Text textAlign="center" color="error.300">
              Are you sure you want to remove this {type} and all its
              information?
            </Text>
            <HStack w="100%" spacing={4}>
              <Button
                w="50%"
                variant="outline"
                borderColor="error.300"
                color="error.300"
                onClick={handleCloseModal}
              >
                No, don't
              </Button>
              <Button
                w="50%"
                colorScheme="red"
                bg="error.300"
                onClick={handleCloseModal}
              >
                Yes, remove
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
