import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";

export default () => {
  return (
    <VStack w={{ base: "100%", md: "xs" }} spacing={8}>
      <Text
        fontWeight="600"
        color="blackAlpha.600"
        display={{ base: "none", md: "block" }}
      >
        To make changes to your profile settings, just click on the input field
        and make your edit.
      </Text>
      <Heading
        w="100%"
        fontSize="1.1rem"
        fontWeight="600"
        display={{ base: "block", md: "none" }}
      >
        Profile Settings
      </Heading>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Full name
        </FormLabel>
        <Input w="100%" h="3rem" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Phone
        </FormLabel>
        <InputGroup>
          <InputLeftElement h="3rem">+1</InputLeftElement>
          <Input w="100%" h="3rem" />
        </InputGroup>
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Country of residence
        </FormLabel>
        <Menu>
          <MenuButton
            w="100%"
            h="3rem"
            as={Button}
            rightIcon={<FiChevronDown />}
            bg="transparent"
            borderWidth="1px"
            borderColor="gray.300"
            textAlign="left"
          >
            <HStack>
              <ReactCountryFlag countryCode="US" svg />
              <Text>United States</Text>
            </HStack>
          </MenuButton>
          <MenuList zIndex={30}>
            {Array.from("123456789").map((_, index) => {
              return (
                <MenuItem key={index}>
                  <HStack>
                    <ReactCountryFlag countryCode="US" svg />
                    <Text>United States</Text>
                  </HStack>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </FormControl>
      <Button w="80%" size="lg" variant="secondary">
        Update profile
      </Button>
      <Button
        w="50%"
        borderRadius={20}
        colorScheme="none"
        bg="error.50"
        color="error.300"
      >
        Remove wallet
      </Button>
    </VStack>
  );
};
