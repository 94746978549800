import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Outlet } from "react-router-dom";
import TabContainer from "../../components/TabContainer";

export default () => {
  return (
    <Flex w="100%" pb={10}>
      <VStack
        py={{ base: 0, md: 8 }}
        px={5}
        alignItems="flex-start"
        spacing={8}
        w={{ base: "100%", md: "calc(100% - 24rem)" }}
        borderRightWidth="1px"
        borderColor="gray.300"
      >
        <HStack spacing={3} display={{ base: "none", md: "flex" }}>
          <Text>Dashboard</Text>
          <Box display={{ base: "none", md: "block" }}>
            <MdOutlineArrowForwardIos size={12} />
          </Box>
          <Text fontWeight="600">My profile</Text>
        </HStack>
        <Heading
          fontSize="1.1rem"
          fontWeight="600"
          display={{ base: "none", md: "block" }}
        >
          My Profile
        </Heading>
        <TabContainer />
        <Outlet />
      </VStack>
      <VStack
        w="24rem"
        spacing={16}
        py={24}
        px={2}
        display={{ base: "none", md: "flex" }}
      >
        <Image src="/investment-live-opportunities.png" w="100%" />
        <VStack spacing={5}>
          <Heading w="100%" fontSize="1rem">
            WATCH HOW TO USE SALVAPAY
          </Heading>
          <Image src="/how-salvapay-works.png" w="100%" />
        </VStack>
        <VStack spacing={5} w="100%">
          <Heading w="100%" fontSize="1rem" textAlign="center">
            DOWNLOAD OUR APP FROM
          </Heading>
          <HStack>
            <Button py={5} px={2} colorScheme="none" color="white" bg="black">
              <HStack>
                <Image src="/playstore.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">GET IT ON</Text>
                  <Heading fontSize="1rem">Google Play</Heading>
                </VStack>
              </HStack>
            </Button>
            <Button py={5} px={2} variant="outline" borderColor="black">
              <HStack>
                <Image src="/apple-logo.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">Download on the</Text>
                  <Heading fontSize="1rem">App Store</Heading>
                </VStack>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  );
};
