import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import RemovePaymentMethodModal from "../dashboard/feedback/RemovePaymentMethodModal";

export default () => {
  const [removePaymentModalActive, setRemovePaymentModalActive] =
    useState(false);

  const handleCloseModal = () => setRemovePaymentModalActive(false);

  return (
    <VStack spacing={8} w={{ base: "100%", md: "sm" }}>
      {removePaymentModalActive ? (
        <RemovePaymentMethodModal
          type="card"
          handleCloseModal={handleCloseModal}
        />
      ) : null}
      <Heading w="100%" fontSize="1.5rem" fontWeight="600">
        My Cards
      </Heading>
      <CardInfo name="John Doe" expiryDate="07/07" lastDigit={3947} />
      <HStack w="100%">
        <Button
          w="50%"
          borderRadius={20}
          bg="lightblue.50"
          color="darkblue.300"
        >
          Add card
        </Button>
        <Button
          w="50%"
          borderRadius={20}
          bg="error.50"
          color="error.300"
          onClick={() => setRemovePaymentModalActive(true)}
        >
          Remove card
        </Button>
      </HStack>
      <Box pos="relative" pt={3} w="100%">
        <Menu>
          <Text pos="absolute" top={0} left={3} px={2} bg="white" zIndex={20}>
            Card currency
          </Text>
          <MenuButton
            w="100%"
            as={Button}
            rightIcon={<FiChevronDown />}
            bg="transparent"
            borderWidth="1px"
            borderColor="gray.300"
            textAlign="left"
            h="3rem"
          >
            <HStack>
              <ReactCountryFlag countryCode="US" svg />
              <Text>US Dollar</Text>
            </HStack>
          </MenuButton>
        </Menu>
      </Box>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Card number
        </FormLabel>
        <Input w="100%" h="3rem" />
      </FormControl>
      <HStack>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Expiry date
          </FormLabel>
          <Input w="100%" h="3rem" />
        </FormControl>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            CVV
          </FormLabel>
          <Input w="100%" h="3rem" />
        </FormControl>
      </HStack>
    </VStack>
  );
};

export const CardInfo = ({
  name,
  expiryDate,
  lastDigit,
}: {
  name: string | undefined;
  expiryDate: string | undefined;
  lastDigit: number | undefined;
}) => {
  return (
    <Box alignSelf="flex-start" pos="relative">
      <Image src="/card-bg.svg" w="100%" />
      <Text pos="absolute" top="4.5rem" right="1.5rem" color="white">
        {lastDigit}
      </Text>
      <HStack
        pos="absolute"
        bottom="0"
        py={5}
        px={8}
        w="100%"
        justifyContent="space-between"
      >
        <VStack spacing={0} alignItems="flex-start">
          <Text color="whiteAlpha.600" fontSize=".7rem">
            Card Holder Name
          </Text>
          <Text color="white" fontSize=".75rem" fontWeight="600">
            {name || null}
          </Text>
        </VStack>
        <VStack spacing={0} alignItems="flex-start">
          <Text color="whiteAlpha.600" fontSize=".7rem">
            Expiry Date
          </Text>
          <Text color="white" fontSize=".75rem">
            {expiryDate || null}
          </Text>
        </VStack>
        <Image src="/Mastercard.png" />
      </HStack>
    </Box>
  );
};
