import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  handleMainModalToRender,
  handleOpenMainModal,
} from "../../features/modals/modalSlice";
import { Outlet, useNavigate } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { useState } from "react";
import { handleCurrentPage } from "../../features/page/pageSlice";

const dummyBeneficiaries: { account: string; bank: string; name: string }[] = [
  { account: "01234567890", bank: "Bank of America", name: "Rachael" },
  { account: "01234567890", bank: "Bank of America", name: "Rachael" },
  { account: "01234567890", bank: "Bank of America", name: "Rachael" },
];

export default () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [mobile] = useMediaQuery("(max-width: 480px)");

  const [isNoBeneficiariesModalOpen, setIsNoBeneficiariesModalOpen] = useState(
    dummyBeneficiaries.length === 0 ? true : false
  );

  const handleCloseNoBeneficiariesModal = () => {
    setIsNoBeneficiariesModalOpen(false);
    dispatch(handleCurrentPage("/"));
    navigate("/dashboard");
  };

  return (
    <Flex w="100%">
      <Outlet />

      <NoBeneficiariesModal
        isNoBeneficiariesModalOpen={isNoBeneficiariesModalOpen}
        handleCloseNoBeneficiariesModal={handleCloseNoBeneficiariesModal}
      />

      <VStack
        py={8}
        px={5}
        alignItems="flex-start"
        spacing={8}
        w="100%"
        borderRightWidth="1px"
        borderColor="gray.300"
      >
        <HStack spacing={3} display={{ base: "none", md: "flex" }}>
          <Text>Dashboard</Text>
          <Box display={{ base: "none", md: "block" }}>
            <MdOutlineArrowForwardIos size={12} />
          </Box>
          <Text fontWeight="600">Beneficiaries</Text>
        </HStack>
        {mobile ? (
          <Heading fontSize="1.1rem" fontWeight="600">
            View All Beneficiaries
          </Heading>
        ) : (
          <Heading fontSize="1.1rem" fontWeight="600">
            All Beneficiaries
          </Heading>
        )}
        <VStack spacing={2} w="100%">
          {dummyBeneficiaries.map(({ account, bank, name }) => {
            return (
              <BeneficiaryCard account={account} bank={bank} name={name} />
            );
          })}
        </VStack>
        <Button
          display={{ base: "none", md: "block" }}
          variant="outline"
          color="darkblue.300"
          borderColor="darkblue.300"
          w="xs"
          onClick={() => {
            navigate("/dashboard/beneficiaries/add");
          }}
        >
          Add beneficiary
        </Button>
        <Button
          w="80%"
          alignSelf="center"
          display={{ base: "block", md: "none" }}
          variant="outline"
          size="lg"
          borderColor="darkblue.300"
          color="darkblue.300"
        >
          Load more
        </Button>
      </VStack>
      <VStack
        w="36rem"
        spacing={16}
        py={24}
        px={2}
        display={{ base: "none", md: "flex" }}
      >
        <Image src="/investment-live-opportunities.png" w="100%" />
        <VStack spacing={5}>
          <Heading w="100%" fontSize="1rem">
            WATCH HOW TO USE SALVAPAY
          </Heading>
          <Image src="/how-salvapay-works.png" w="100%" />
        </VStack>
        <VStack spacing={5} w="100%">
          <Heading w="100%" fontSize="1rem" textAlign="center">
            DOWNLOAD OUR APP FROM
          </Heading>
          <HStack>
            <Button py={5} px={2} colorScheme="none" color="white" bg="black">
              <HStack>
                <Image src="/playstore.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">GET IT ON</Text>
                  <Heading fontSize="1rem">Google Play</Heading>
                </VStack>
              </HStack>
            </Button>
            <Button py={5} px={2} variant="outline" borderColor="black">
              <HStack>
                <Image src="/apple-logo.png" />
                <VStack spacing={0} alignItems="left" textAlign="left">
                  <Text fontSize="0.6rem">Download on the</Text>
                  <Heading fontSize="1rem">App Store</Heading>
                </VStack>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  );
};

const BeneficiaryCard = ({
  account,
  bank,
  name,
}: {
  account: string;
  bank: string;
  name: string;
}) => {
  const navigate = useNavigate();

  return (
    <HStack
      bg={{ base: "lightblue.50", md: "transparent" }}
      w="100%"
      spacing={{ base: 2, md: 8 }}
      borderWidth="1px"
      borderColor="dark.50"
      borderRadius={5}
      py={{ base: 2, md: 1 }}
      px={{ base: 2, md: 4 }}
      onClick={() => navigate("/dashboard/beneficiaries/view")}
    >
      <Avatar bg="purple.300" color="light.100" size="md" name={name} />
      <VStack alignItems="flex-start" w="60%">
        <Text fontWeight="600">{name}</Text>
        <Text color="dark.100">{bank}</Text>
      </VStack>
      <Spacer />
      <HStack spacing={8} fontWeight="600" alignSelf="flex-start">
        <Text>{account}</Text>
        <Box>
          <MdOutlineArrowForwardIos size={12} />
        </Box>
      </HStack>
    </HStack>
  );
};

const NoBeneficiariesModal = ({
  handleCloseNoBeneficiariesModal,
  isNoBeneficiariesModalOpen,
}: {
  handleCloseNoBeneficiariesModal: () => void;
  isNoBeneficiariesModalOpen: boolean;
}) => {
  return (
    <Modal
      onClose={handleCloseNoBeneficiariesModal}
      isOpen={isNoBeneficiariesModalOpen}
      motionPreset="slideInBottom"
      isCentered
      size="sm"
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <ModalCloseButton color="error.300" size="sm" />
          <VStack spacing={6}>
            <Heading fontSize="1.5rem" fontWeight="600">
              No beneficiary added!
            </Heading>
            <Text color="blackAlpha.800" textAlign="center">
              You have not added any beneficiary to this account yet.
            </Text>
            <Button
              w="80%"
              size="lg"
              variant="secondary"
              onClick={handleCloseNoBeneficiariesModal}
            >
              Go to dashboard
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
