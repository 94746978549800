import { Box, VStack } from "@chakra-ui/react";
import Header from "./Header";

export default ({ children }: { children: JSX.Element }) => {
  return (
    <VStack spacing={5}>
      <Box w="100%" display={{ base: "flex", md: "none" }}>
        <Header />
      </Box>
      <Box w="100%">{children}</Box>
    </VStack>
  );
};
