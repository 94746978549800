import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  PinInput,
  PinInputField,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import ExchangeRate from "./ExchangeRate";
import TransferFeedbackModal from "../feedback/TransferFeedbackModal";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import DashboardModal from "./DashboardModal";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import { AnimatePresence, motion } from "framer-motion";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate("/dashboard")}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState<boolean | undefined>();

  return (
    <MobileModalWrapper>
      {step !== 1 ? (
        <AnimatePresence>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ x: step === 2 ? "-100%" : 0 }}
            animate={{ x: 0 }}
          >
            <VStack spacing={5} p={{ base: 5, md: 0 }}>
              <HStack
                as={Button}
                w="fit-content"
                alignSelf="flex-start"
                spacing={3}
                colorScheme="none"
                color="dark.500"
                px={0}
              >
                <Center
                  p={2}
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius={5}
                  display={{ base: "none", md: "block" }}
                  onClick={() => navigate(-1)}
                >
                  <MdOutlineArrowBackIosNew />
                </Center>
                <Text fontWeight="600">Send Money</Text>
              </HStack>
              <ExchangeRate title="You send" />
              <ExchangeRate title="Recipient receives" />
              <VStack py={5} w="100%" color="blackAlpha.700" spacing={5}>
                <Flex fontWeight="600" w="100%">
                  <Text>Service charge</Text>
                  <Spacer />
                  <Text>3.5 USD</Text>
                </Flex>
                <Flex fontWeight="600" w="100%">
                  <Text>Conversion rate</Text>
                  <Spacer />
                  <Text>1 GBP = 500.65 GBP</Text>
                </Flex>
                <Flex fontWeight="600" w="100%">
                  <Text>Total amount</Text>
                  <Spacer />
                  <Text color="black">1,003.5 USD</Text>
                </Flex>
              </VStack>
              <Text w="100%" color="black">
                Payment should reflect in recipient's account after 48 hours
              </Text>
              <Button
                size="lg"
                variant="secondary"
                w="85%"
                borderRadius={3}
                onClick={() => setStep(1)}
              >
                Continue
              </Button>
            </VStack>
          </motion.div>
        </AnimatePresence>
      ) : (
        <motion.div initial={{ x: "100%" }} animate={{ x: 0 }}>
          <VStack spacing={5} p={{ base: 5, md: 0 }}>
            {success !== undefined ? <TransferFeedbackModal /> : null}
            <HStack
              as={Button}
              w="fit-content"
              alignSelf="flex-start"
              spacing={3}
              colorScheme="none"
              color="dark.500"
              px={0}
            >
              <Center
                p={2}
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius={5}
                display={{ base: "none", md: "block" }}
                onClick={() => setStep(2)}
              >
                <MdOutlineArrowBackIosNew />
              </Center>
              <Text fontWeight="600">Send Money</Text>
            </HStack>
            <VStack w="100%">
              <Text w="100%" pt={5} color="blackAlpha.700">
                Sender information
              </Text>
              <Box pos="relative" pt={3} w="100%">
                <Menu>
                  <Text
                    pos="absolute"
                    top={0}
                    left={3}
                    px={2}
                    bg="white"
                    zIndex={20}
                  >
                    Select wallet
                  </Text>
                  <MenuButton
                    w="100%"
                    as={Button}
                    rightIcon={<FiChevronDown />}
                    bg="transparent"
                    borderWidth="1px"
                    borderColor="gray.300"
                    textAlign="left"
                    h="3rem"
                    _hover={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                  >
                    <HStack>
                      <ReactCountryFlag countryCode="US" svg />
                      <Text>US Dollar</Text>
                    </HStack>
                  </MenuButton>
                </Menu>
              </Box>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Amount
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <Text w="100%" color="blackAlpha.700">
                Recipient information
              </Text>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Account number
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Account name
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Narration (Optional)
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <Box w="100%" py={3}>
                <Checkbox>Add as beneficiary</Checkbox>
              </Box>
              <VStack
                w="100%"
                p={2}
                borderWidth="1px"
                borderColor="lightblue.100"
                borderRadius={5}
                spacing={5}
              >
                <Text fontSize=".9rem">Enter your 4-digit PIN</Text>
                <HStack>
                  <PinInput placeholder=" ">
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                  </PinInput>
                </HStack>
              </VStack>
              <Box py={8} w="80%">
                <Button
                  size="lg"
                  colorScheme="green"
                  bg="success.300"
                  w="100%"
                  onClick={() => {
                    setSuccess(true);
                  }}
                >
                  Send
                </Button>
              </Box>
            </VStack>
          </VStack>
        </motion.div>
      )}
    </MobileModalWrapper>
  );
};
