import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [success] = useState(true);

  return (
    <Modal
      onClose={() => navigate("/dashboard")}
      isOpen={true}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6}>
            {success ? (
              <Image src="/transfer-success.png" />
            ) : (
              <Image src="/transfer-failure.png" />
            )}

            {success ? (
              <Heading fontSize="1.2rem" fontWeight="600">
                Your transfer was successful
              </Heading>
            ) : (
              <Heading fontSize="1.2rem" fontWeight="600" color="red">
                Your transfer was not successful
              </Heading>
            )}

            {success ? (
              <Text textAlign="center">
                Transfer of US$1,003.5 from your US Dollar wallet to Famous
                Write was successful.
              </Text>
            ) : (
              <Text textAlign="center" color="red">
                Transfer of US$1,003.5 from your US Dollar wallet to Famous
                Write was not successful.
              </Text>
            )}
            <Flex
              flexDir="row"
              wrap={{ base: "wrap", md: "nowrap" }}
              w={{ base: "80%", md: "100%" }}
              gap={4}
            >
              <Button
                w="100%"
                size="lg"
                borderColor={success ? "darkblue.300" : "error.300"}
                variant="outline"
                color={success ? "darkblue.300" : "error.300"}
                onClick={() => navigate("/dashboard")}
              >
                Go to dashboard
              </Button>
              {success ? (
                <Button w="100%" size="lg" variant="secondary">
                  Share receipt
                </Button>
              ) : (
                <Button w="100%" size="lg" bg="error.300">
                  Share receipt
                </Button>
              )}
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
