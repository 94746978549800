import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import { CardInfo } from "../../profile/Card";
import AddCardFeedbackModal from "../feedback/AddCardFeedbackModal";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate("/dashboard")}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean | undefined>();

  return (
    <MobileModalWrapper>
      <VStack spacing={5} py={{ base: 5, md: 0 }} px={{ base: 5, md: 0 }}>
        {success !== undefined ? (
          <AddCardFeedbackModal success={success} setSuccess={setSuccess} />
        ) : null}
        <HStack w="100%" spacing={3}>
          <Center
            p={2}
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius={5}
            display={{ base: "none", md: "block" }}
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBackIosNew />
          </Center>
          <Text fontWeight="600">Add a Card</Text>
        </HStack>
        <CardInfo name={undefined} expiryDate={undefined} lastDigit={3947} />
        <Box pos="relative" pt={3} w="100%">
          <Menu>
            <Text pos="absolute" top={0} left={3} px={2} bg="white" zIndex={20}>
              Select card currency
            </Text>
            <MenuButton
              w="100%"
              as={Button}
              rightIcon={<FiChevronDown />}
              bg="transparent"
              borderWidth="1px"
              borderColor="gray.300"
              textAlign="left"
              h="3rem"
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
            >
              <HStack>
                <ReactCountryFlag countryCode="US" svg />
                <Text>US Dollar</Text>
              </HStack>
            </MenuButton>
          </Menu>
        </Box>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Card number
          </FormLabel>
          <Input w="100%" h="3rem" />
        </FormControl>
        <HStack>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={0}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              Expiry date
            </FormLabel>
            <Input w="100%" h="3rem" />
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={0}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              CVV
            </FormLabel>
            <Input w="100%" h="3rem" />
          </FormControl>
        </HStack>
        <Checkbox w="100%">Save this card for future payment</Checkbox>
        <Box w="80%" py={8}>
          <Button
            w="100%"
            size="lg"
            variant="secondary"
            onClick={() => setSuccess(false)}
          >
            Add card
          </Button>
        </Box>
      </VStack>
    </MobileModalWrapper>
  );
};
