import { useSelector } from "react-redux";
import Notifications from "./Notifications";
import SettingsModal from "./SettingsModal";

export default () => {
  const { mainModalToRender } = useSelector((state: RootState) => state.modal);

  switch (mainModalToRender) {
    case "Notifications":
      return <Notifications />;

    default:
      return <SettingsModal />;
  }
};
