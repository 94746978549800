import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import DashboardWrapper from "./pages/dashboard/DashboardWrapper";
import Dashboard from "./pages/dashboard/Dashboard";
import Transactions from "./pages/dashboard/Transactions";
import Faqs from "./pages/Faqs";
import Profile from "./pages/profile/Profile";
import ProfileWrapper from "./pages/profile/ProfileWrapper";
import KYC from "./pages/profile/KYC";
import Security from "./pages/profile/Security";
import Card from "./pages/profile/Card";
import Contact from "./pages/profile/Contact";
import Beneficiaries from "./pages/dashboard/Beneficiaries";
import SendMoney from "./pages/dashboard/modals/SendMoney";
import ReceiveMoney from "./pages/dashboard/modals/ReceiveMoney";
import FundWallet from "./pages/dashboard/modals/FundWallet";
import AddBeneficiary from "./pages/dashboard/modals/AddBeneficiary";
import BeneficiaryDetials from "./pages/dashboard/modals/BeneficiaryDetials";
import AddCard from "./pages/dashboard/modals/AddCard";

export default () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<DashboardWrapper />}>
            <Route path="" element={<Dashboard />}>
              <Route path="add-card" element={<AddCard />} />
              <Route path="send" element={<SendMoney />} />
              <Route path="request" element={<ReceiveMoney />} />
              <Route path="fund" element={<FundWallet />} />
              <Route path="add-beneficiary" element={<AddBeneficiary />} />
              <Route path="view-beneficiary" element={<BeneficiaryDetials />} />
            </Route>
            <Route path="settings" element={<ProfileWrapper />}>
              <Route path="" element={<Profile />} />
              <Route path="kyc" element={<KYC />} />
              <Route path="security" element={<Security />} />
              <Route path="card" element={<Card />} />
              <Route path="contact" element={<Contact />} />
            </Route>
            <Route path="transactions" element={<Transactions />} />
            <Route path="beneficiaries" element={<Beneficiaries />}>
              <Route path="add" element={<AddBeneficiary />} />
              <Route path="view" element={<BeneficiaryDetials />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
