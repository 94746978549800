import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    handleToggleHeaderDropdown: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { handleToggleHeaderDropdown } = headerSlice.actions;

export default headerSlice.reducer;
