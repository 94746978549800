import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiEye } from "react-icons/fi";

export default () => {
  return (
    <Box w="100%">
      <Heading
        fontSize="1.1rem"
        fontWeight="600"
        w="100%"
        pb={8}
        display={{ base: "block", md: "none" }}
      >
        Security Settings
      </Heading>
      <Flex
        flexDir="row"
        wrap={{ base: "wrap", md: "nowrap" }}
        w="100%"
        gap={8}
      >
        <VStack spacing={5} w={{ base: "100%", md: "50%" }}>
          <Heading w="100%" fontSize="1.2rem" fontWeight="600">
            Change Email Address
          </Heading>
          <Text w="100%">Use the form below to change your email address.</Text>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              Old email
            </FormLabel>
            <Input w="100%" h="3rem" />
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              New email
            </FormLabel>
            <Input w="100%" h="3rem" />
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              Retype new email
            </FormLabel>
            <Input w="100%" h="3rem" />
          </FormControl>
          <Button w="80%" h="3rem" variant="secondary">
            Update email
          </Button>
        </VStack>
        <VStack spacing={5} w={{ base: "100%", md: "50%" }}>
          <Heading w="100%" fontSize="1.2rem" fontWeight="600">
            Change Your Password
          </Heading>
          <Text w="100%">Use the form below to change your password.</Text>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              Old password
            </FormLabel>
            <InputGroup>
              <Input w="100%" h="3rem" />
              <InputRightElement h="3rem">
                <Button bg="transparent" p={0}>
                  <FiEye />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              New password
            </FormLabel>
            <InputGroup>
              <Input w="100%" h="3rem" />
              <InputRightElement h="3rem">
                <Button bg="transparent" p={0}>
                  <FiEye />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={1}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
            >
              Retype password
            </FormLabel>
            <InputGroup>
              <Input w="100%" h="3rem" />
              <InputRightElement h="3rem">
                <Button bg="transparent" p={0}>
                  <FiEye />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button w="80%" h="3rem" variant="secondary">
            Update password
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
};
