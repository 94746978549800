import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { BsBell, BsInfoCircle } from "react-icons/bs";
import { TbArrowsDownUp, TbHome2, TbLogout, TbSettings } from "react-icons/tb";
import { BsPeople } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import {
  handleCloseMainModal,
  handleMainModalToRender,
  handleOpenMainModal,
  handleToggleLogoutDialog,
} from "../../features/modals/modalSlice";
import DashboardModal from "./modals/DashboardModal";
import { useRef } from "react";
import { handleCurrentPage } from "../../features/page/pageSlice";

export default () => {
  const dispatch = useDispatch();

  const [mobile] = useMediaQuery("(max-width: 480px)");

  const { isLogoutDialogActive, isMainModalActive } = useSelector(
    (state: RootState) => state.modal
  );

  const { currentPage } = useSelector((state: RootState) => state.page);

  const btnRef = useRef<any>();

  useEffect(() => {
    const getCurrentPage = () => {
      if (currentPage.includes("settings")) {
        dispatch(handleCurrentPage("/settings"));
      } else if (currentPage.includes("transactions")) {
        dispatch(handleCurrentPage("/transactions"));
      } else if (currentPage.includes("beneficiaries")) {
        dispatch(handleCurrentPage("/beneficiaries"));
      } else if (currentPage.includes("notifications")) {
        dispatch(handleCurrentPage("/notifications"));
      } else {
        dispatch(handleCurrentPage("/"));
      }
    };

    getCurrentPage();
  }, []);

  return mobile ? (
    <>
      <LogoutModal
        isOpen={isLogoutDialogActive}
        onClose={() => dispatch(handleToggleLogoutDialog())}
      />

      <>
        <Drawer
          isOpen={isMainModalActive}
          placement="right"
          onClose={() => dispatch(handleCloseMainModal())}
          finalFocusRef={btnRef}
          size={{ base: "full", md: "secondary" }}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody p={{ base: 0, md: 5 }}>
              <DashboardModal />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>

      <Header />
      <Outlet />
    </>
  ) : (
    <Box>
      <>
        <Drawer
          isOpen={isMainModalActive}
          placement="right"
          onClose={() => dispatch(handleCloseMainModal())}
          finalFocusRef={btnRef}
          size={{ base: "full", md: "secondary" }}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody p={{ base: 0, md: 5 }}>
              <DashboardModal />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>

      <Flex>
        <VStack
          borderRightWidth="1px"
          borderColor="gray.300"
          w="16rem"
          py={16}
          spacing={4}
        >
          <Image pb={5} src="/salvapay-icon.svg" />
          <NavigationButton
            props={{
              icon: <TbHome2 />,
              link: "/",
              title: "Dashboard",
            }}
          />
          <NavigationButton
            props={{
              icon: <TbArrowsDownUp />,
              link: "/transactions",
              title: "All transactions",
            }}
          />
          <NavigationButton
            props={{
              icon: <TbSettings />,
              link: "/settings",
              title: "My profile",
            }}
          />
          <NavigationButton
            props={{
              icon: <BsPeople />,
              link: "/beneficiaries",
              title: "Beneficiaries",
            }}
          />
          <Button
            onClick={() => {
              dispatch(handleMainModalToRender("Notifications"));
              dispatch(handleOpenMainModal());
            }}
            bg="transparent"
            color="darkblue.300"
            leftIcon={<BsBell />}
            w="100%"
            borderRadius={0}
            h="3rem"
            fontWeight="500"
            variant="primary"
          >
            <Text w="100%" textAlign="left">
              Notifications
            </Text>
          </Button>
          <LogoutModal
            isOpen={isLogoutDialogActive}
            onClose={() => dispatch(handleToggleLogoutDialog())}
          />
          <Box pt={10} w="100%">
            <Button
              leftIcon={<TbLogout size={20} />}
              w="100%"
              h="3rem"
              borderRadius={0}
              bg="transparent"
              color="red"
              fontWeight="500"
              onClick={() => dispatch(handleToggleLogoutDialog())}
            >
              <Text w="100%" textAlign="left">
                Logout
              </Text>
            </Button>
          </Box>
        </VStack>
        <Outlet />
      </Flex>
    </Box>
  );
};

const NavigationButton = ({ props }: NavigationButtonProps) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { currentPage } = useSelector((state: RootState) => state.page);

  const { icon, link, title } = props;

  return (
    <Button
      onClick={() => {
        dispatch(handleCurrentPage(link));
        navigate(`/dashboard${link}`);
      }}
      bg={currentPage === link ? "lightblue.300" : "transparent"}
      color="darkblue.300"
      leftIcon={icon}
      w="100%"
      borderRadius={0}
      h="3rem"
      fontWeight="500"
      variant="primary"
    >
      <Text w="100%" textAlign="left">
        {title}
      </Text>
    </Button>
  );
};

export const LogoutModal = ({ isOpen, onClose }: any) => {
  const navigate = useNavigate();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack alignItems="flex-start" spacing={6}>
            <Center w="2rem" h="2rem" bg="red.100" borderRadius="100%">
              <Box color="red">
                <BsInfoCircle />
              </Box>
            </Center>
            <VStack alignItems="flex-start">
              <Heading fontSize="1.1rem" fontWeight="600">
                Logout!
              </Heading>
              <Text color="blackAlpha.800">
                Are you sure you want to logout?
              </Text>
            </VStack>
            <Flex
              flexDir="row"
              wrap={{ base: "wrap", md: "nowrap" }}
              w="100%"
              gap={4}
            >
              <Button
                w="100%"
                borderColor="error.300"
                variant="outline"
                // color="red"
                onClick={onClose}
              >
                No, don't
              </Button>
              <Button
                w="100%"
                colorScheme="red"
                bg="error.300"
                onClick={() => navigate("/")}
              >
                Logout
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
