import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";

export default ({
  success,
  setSuccess,
}: {
  success: boolean | undefined;
  setSuccess: any;
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Modal
      onClose={() => dispatch(handleCloseMainModal())}
      isOpen={true}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6}>
            <Image src="/credit-card-outline.png" alt="Credit card" />
            {success ? (
              <Heading fontSize="1.3rem" fontWeight="600">
                Card added!
              </Heading>
            ) : (
              <Heading fontSize="1.3rem" fontWeight="600" color="red">
                Card not added!
              </Heading>
            )}
            {success ? (
              <Text textAlign="center" color="darkblue.300">
                For safety purpose, you can add a new card or view your card(s)
                in <b>my profile</b> page.
              </Text>
            ) : (
              <Text textAlign="center" color="error.300">
                For some reason, this card could not be added at the moment.
                Please, try again later.
              </Text>
            )}
            <Flex
              flexDir="row"
              w={{ base: "80%", md: "100%" }}
              gap={4}
              wrap={{ base: "wrap", md: "nowrap" }}
            >
              {success ? (
                <Button
                  w="100%"
                  variant="outline"
                  borderColor="darkblue.300"
                  color="darkblue.300"
                >
                  View my card
                </Button>
              ) : (
                <Button
                  w="100%"
                  variant="outline"
                  borderColor="error.300"
                  color="error.300"
                  onClick={() => navigate("/dashboard")}
                >
                  Go to dashboard
                </Button>
              )}
              {success ? (
                <Button
                  w="100%"
                  variant="secondary"
                  onClick={() => navigate("/dashboard")}
                >
                  Go to dashboard
                </Button>
              ) : (
                <Button
                  w="100%"
                  colorScheme="red"
                  bg="error.300"
                  onClick={() => setSuccess()}
                >
                  Try again
                </Button>
              )}
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
