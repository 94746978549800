import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  TbBrandFacebook,
  TbBrandLinkedin,
  TbBrandTwitter,
} from "react-icons/tb";

export default () => {
  return (
    <VStack alignItems="flex-start" w={{ base: "100%", md: "sm" }} spacing={5}>
      <VStack spacing={4}>
        <Heading w="100%" fontSize="1.1rem" fontWeight="500">
          Contact Salvapay
        </Heading>
        <Text color="light.500">
          Feel free to reach Salvapay via our email, phone number or any of our
          social media channels.
        </Text>
      </VStack>
      <HStack spacing={10} w="100%">
        <ShareCard icon={<TbBrandTwitter />} title="Twitter" />
        <ShareCard icon={<TbBrandFacebook />} title="Facebook" />
        <ShareCard icon={<TbBrandLinkedin />} title="Linkedin" />
      </HStack>
      <VStack w="100%">
        <CopyCard title="Phone number" value="+44 (704) 000-1234" />
        <CopyCard title="Email address" value="uk@salvapay.com" />
      </VStack>
      <VStack py={8} spacing={3} alignItems="flex-start">
        <Heading w="100%" fontSize="1.1rem" fontWeight="500">
          Notification Settings
        </Heading>
        <Text color="light.500" py={3}>
          Have control over the kind of messages you receive from Salvapay
        </Text>
        <HStack>
          <Switch defaultChecked />
          <Text>Disable marketing mails/SMS</Text>
        </HStack>
        <HStack>
          <Switch defaultChecked />
          <Text>Disable daily/weekly notifications</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

const ShareCard = ({ icon, title }: { icon: JSX.Element; title: string }) => {
  return (
    <VStack>
      <Center
        p={3}
        borderWidth="1px"
        borderColor="dark.50"
        borderRadius={10}
        color="darkblue.300"
      >
        {icon}
      </Center>
      <Text color="darkblue.300" fontWeight="600">
        {title}
      </Text>
    </VStack>
  );
};

const CopyCard = ({ title, value }: { title: string; value: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopied = () => setCopied(true);

  return (
    <FormControl pos="relative" w="100%" pt={3.5} color="dark.100">
      <FormLabel pos="absolute" top={0} left={3} px={2} bg="white" zIndex={20}>
        {title}
      </FormLabel>
      <HStack>
        <InputGroup>
          <InputLeftElement h="3rem">
            <ReactCountryFlag countryCode="GB" svg />
          </InputLeftElement>
          <Input value={value} readOnly w="xs" h="3rem" />
        </InputGroup>
        {copied ? (
          <Button
            bg="transparent"
            color="darkblue.300"
            _hover={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
          >
            Copied
          </Button>
        ) : (
          <Button
            bg="transparent"
            color="darkblue.300"
            _hover={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            onClick={handleCopied}
          >
            Copy
          </Button>
        )}
      </HStack>
    </FormControl>
  );
};
