import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import {
  BsArrow90DegDown,
  BsArrow90DegUp,
  BsBell,
  BsInfoCircleFill,
  BsPlus,
} from "react-icons/bs";
import { CiWallet } from "react-icons/ci";
import DashboardModal from "./modals/DashboardModal";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenMainModal,
  handleCloseMainModal,
  handleMainModalToRender,
} from "../../features/modals/modalSlice";
import { TransactionCard } from "./Transactions";
import { Outlet, useNavigate } from "react-router-dom";
import { handleCurrentPage } from "../../features/page/pageSlice";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const beneficiaries = [
  { account: "01234567890", bank: "Chase bank", name: "Write Famous" },
];

export default () => {
  const btnRef = useRef<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMainModalActive } = useSelector((state: RootState) => state.modal);

  const [transactionsExist] = useState(true);

  return (
    <Flex w="100%" mx="auto">
      <Box w="100%" px={{ base: 5, md: 0 }}>
        <Main />
      </Box>

      <Outlet />

      <VStack
        w="35rem"
        py={10}
        px={5}
        spacing={5}
        display={{ base: "none", md: "flex" }}
        borderLeftWidth="1px"
        borderColor="gray.300"
        // h="100vh"
        // pos="sticky"
        // alignSelf="flex-start"
        // top={0}
      >
        <Heading w="100%" fontSize="1rem" fontWeight="600">
          Recent Transactions
        </Heading>
        {transactionsExist ? (
          <VStack w="100%" spacing={8}>
            <VStack w="100%">
              <Heading
                w="100%"
                fontSize="1rem"
                fontWeight="600"
                color="dark.100"
              >
                TODAY
              </Heading>
              <TransactionCard />
              <TransactionCard />
            </VStack>
            <VStack w="100%">
              <Heading
                w="100%"
                fontSize="1rem"
                fontWeight="600"
                color="dark.100"
              >
                YESTERDAY
              </Heading>
              <TransactionCard />
              <TransactionCard />
            </VStack>
            <VStack w="100%">
              <Heading
                w="100%"
                fontSize="1rem"
                fontWeight="600"
                color="dark.100"
              >
                01 JANUARY, 2023
              </Heading>
              <TransactionCard />
              <TransactionCard />
            </VStack>
            <Box w="70%" py={8} alignSelf="center">
              <Button
                w="100%"
                variant="outline"
                color="darkblue.300"
                borderColor="darkblue.300"
                onClick={() => {
                  dispatch(handleCurrentPage("/transactions"));
                  navigate("/dashboard/transactions");
                }}
              >
                View all transaction
              </Button>
            </Box>
          </VStack>
        ) : (
          <Text w="100%" color="gray.500">
            You have not performed any transaction yet
          </Text>
        )}
        <Spacer />
        <Heading fontSize="1rem" fontWeight="600">
          DOWNLOAD OUR APP FROM
        </Heading>
        <HStack>
          <Button py={5} px={2} colorScheme="none" color="white" bg="black">
            <HStack>
              <Image src="/playstore.png" />
              <VStack spacing={0} alignItems="left" textAlign="left">
                <Text fontSize="0.6rem">GET IT ON</Text>
                <Heading fontSize="1rem">Google Play</Heading>
              </VStack>
            </HStack>
          </Button>
          <Button py={5} px={2} variant="outline" borderColor="black">
            <HStack>
              <Image src="/apple-logo.png" />
              <VStack spacing={0} alignItems="left" textAlign="left">
                <Text fontSize="0.6rem">Download on the</Text>
                <Heading fontSize="1rem">App Store</Heading>
              </VStack>
            </HStack>
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <VStack w="100%" py={10} spacing={10} px={{ md: 5 }}>
      <HStack justifyContent="space-between" w="100%">
        <VStack w="100%">
          <Heading w="100%" fontSize="1.2rem" fontWeight="600">
            Welcome Jon!
          </Heading>
          <Text color="blackAlpha.700" w="100%">
            Today's date is 15/12/2022 01:00:56 PM
          </Text>
        </VStack>
        <Center p={2} borderWidth="1px" borderColor="dark.50" borderRadius={5}>
          <BsBell />
        </Center>
      </HStack>
      <MyWalletCard />
      <VStack spacing={5} w="100%">
        <Heading w="100%" fontSize="1.5rem" fontWeight="600">
          Quick Actions
        </Heading>
        <HStack
          w="100%"
          justifyContent="space-between"
          spacing={{ base: 3, md: 8 }}
        >
          <ActionCard
            icon={<BsArrow90DegUp size={20} />}
            link="/send"
            title="Send money"
            color="darkblue.300"
          />
          <ActionCard
            icon={<BsArrow90DegDown size={20} />}
            link="/request"
            title="Receive money"
            color="lightblue.300"
          />
          <ActionCard
            icon={<CiWallet size={20} />}
            link="/fund"
            title="Fund wallet"
            color="dark.300"
          />
        </HStack>
      </VStack>
      <MyCards />
      <VStack w="100%" spacing={5} display={{ base: "flex", md: "none" }}>
        <Heading w="100%" fontSize="1.1rem" fontWeight="600">
          Recent Transactions
        </Heading>
        <Heading w="100%" fontSize="1.1rem" fontWeight="600" color="dark.100">
          TODAY
        </Heading>
        <TransactionCard />
        <TransactionCard />
        <Button
          w="80%"
          variant="outline"
          color="darkblue.300"
          borderColor="darkblue.300"
          size="lg"
          onClick={() => {
            dispatch(handleCurrentPage("/transactions"));
            navigate("/dashboard/transactions");
          }}
        >
          View all transaction
        </Button>
      </VStack>
      <MyBeneficiaries />
    </VStack>
  );
};

const MyWalletCard = () => {
  const navigate = useNavigate();

  return (
    <VStack
      w="100%"
      boxShadow="lg"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius={10}
      py={5}
      spacing={{ base: 2, md: 4 }}
    >
      <Heading fontSize="1rem" fontWeight="500" color="dark.100">
        My wallet balance
      </Heading>
      <Text fontSize="1.5rem" fontWeight="600">
        $0.00
      </Text>
      <Button
        bg="blue.50"
        color="blue.700"
        w="10rem"
        borderRadius={20}
        onClick={() => navigate("/dashboard/send")}
      >
        Top up
      </Button>
    </VStack>
  );
};

const ActionCard = ({ color, icon, link, title }: ActionCard) => {
  const navigate = useNavigate();

  return (
    <VStack
      as={Button}
      colorScheme="transparent"
      w="33%"
      h={{ base: "fit-content", md: "12rem" }}
      py={3}
      borderWidth="1px"
      borderColor={color}
      color={color}
      alignItems="center"
      justifyContent="center"
      borderRadius={5}
      onClick={() => navigate("/dashboard" + link)}
      _hover={{
        bg: color,
        color: "light.100",
      }}
      transition=".5s"
    >
      {icon}
      <Text>{title}</Text>
    </VStack>
  );
};

const MyCards = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <VStack w="100%" spacing={5}>
      <Heading fontSize="1.5rem" fontWeight="600" w="100%">
        My Cards
      </Heading>
      <HStack
        w="100%"
        py={1}
        px={2}
        borderWidth="1px"
        borderColor="brand.purple"
        borderRadius={5}
        spacing={3}
      >
        <BsInfoCircleFill size={25} />
        <Text w={{ base: "50%", md: "fit-content" }} color="dark.100">
          You currently have no card attached to this account.
        </Text>
        <Spacer />
        <Button
          variant="secondary"
          h="2rem"
          onClick={() => navigate("/dashboard/add-card")}
        >
          Add a card
        </Button>
      </HStack>
    </VStack>
  );
};

const MyBeneficiaries = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <VStack w="100%" spacing={5}>
      <HStack w="100%">
        <Heading fontSize="1.5rem" fontWeight="600">
          Beneficiaries
        </Heading>
        <Spacer />
        <Button
          onClick={() => navigate("/dashboard/add-beneficiary")}
          rightIcon={
            <Center
              p={2}
              borderRadius={3}
              borderWidth="1px"
              borderColor="gray.300"
            >
              <BsPlus />
            </Center>
          }
          bg="transparent"
          px={0}
          display={{ base: "none", md: "block" }}
          _hover={{ bg: "transparent" }}
          _focus={{ bg: "transparent" }}
        >
          Add beneficiary
        </Button>
      </HStack>
      {beneficiaries.length > 0 ? (
        <VStack w="100%">
          {beneficiaries.map(({ account, bank, name }, index) => (
            <BeneficiaryCard
              key={index}
              account={account}
              bank={bank}
              name={name}
            />
          ))}
          <Box py={8} display={{ base: "block", md: "none" }} w="80%">
            <Button
              onClick={() => {
                dispatch(handleCurrentPage("/beneficiaries"));
                navigate("/dashboard/beneficiaries");
              }}
              w="100%"
              size="lg"
              variant="outline"
              borderColor="darkblue.300"
              color="darkblue.300"
            >
              View all
            </Button>
          </Box>
        </VStack>
      ) : (
        <HStack
          w="100%"
          py={1}
          px={2}
          borderWidth="1px"
          borderColor="blue.500"
          borderRadius={5}
          spacing={3}
        >
          <BsInfoCircleFill />
          <Text w="50%" color="dark.100">
            You have not added any beneficiary.
          </Text>
          <Spacer />
          <Button leftIcon={<BsPlus />} variant="secondary" w="fit-content">
            Add now
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

const BeneficiaryCard = ({
  account,
  bank,
  name,
}: {
  account: string;
  bank: string;
  name: string;
}) => {
  const navigate = useNavigate();

  return (
    <HStack
      bg={{ base: "lightblue.50", md: "transparent" }}
      w="100%"
      spacing={{ base: 2, md: 8 }}
      borderWidth="1px"
      borderColor="dark.50"
      borderRadius={5}
      py={{ base: 2, md: 1 }}
      px={{ base: 2, md: 4 }}
      onClick={() => navigate("/dashboard/view-beneficiary")}
    >
      <Avatar bg="purple.300" color="light.100" size="md" name={name} />
      <VStack alignItems="flex-start" w="60%">
        <Text fontWeight="600">{name}</Text>
        <Text color="dark.100">{bank}</Text>
      </VStack>
      <Spacer />
      <HStack spacing={8} fontWeight="600" alignSelf="flex-start">
        <Text>{account}</Text>
        <Box>
          <MdOutlineArrowForwardIos size={12} />
        </Box>
      </HStack>
    </HStack>
  );
};
