import {
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";

export default ({ success }: { success: boolean }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Modal
      onClose={() => dispatch(handleCloseMainModal())}
      isOpen={true}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6}>
            <Center
              bg={success ? "success.50" : "error.50"}
              color={success ? "success.300" : "error.300"}
              p={3}
              borderRadius="100%"
            >
              <BsInfoCircle />
            </Center>
            )
            {success ? (
              <Heading fontSize="1.2rem" fontWeight="600">
                Beneficiary added!
              </Heading>
            ) : (
              <Heading fontSize="1.2rem" fontWeight="600" color="red">
                Beneficiary not added!
              </Heading>
            )}
            {success ? (
              <Text textAlign="center" color="darkblue.300">
                You have successfully added a new beneficiary.
              </Text>
            ) : (
              <Text textAlign="center" color="error.300">
                For some reason, this beneficiary could not be added at the
                moment. Please, try again later.
              </Text>
            )}
            <VStack w="xs" spacing={4}>
              <Button
                w="100%"
                borderColor={success ? "darkblue.300" : "error.300"}
                variant="outline"
                color={success ? "darkblue.300" : "error.300"}
                onClick={() => navigate(-1)}
              >
                Go to dashboard
              </Button>
              {success ? (
                <Button w="100%" variant="secondary">
                  Send money
                </Button>
              ) : (
                <Button w="100%" colorScheme="red" bg="error.300">
                  Try again
                </Button>
              )}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
