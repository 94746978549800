import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate(-1)}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <MobileModalWrapper>
      <VStack spacing={5} py={{ base: 5, md: 0 }} px={{ base: 5, md: 0 }}>
        <RemoveBeneficiaryModal isOpen={isOpen} onClose={onClose} />
        <HStack w="100%" spacing={3}>
          <Center
            p={2}
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius={5}
            display={{ base: "none", md: "block" }}
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBackIosNew />
          </Center>
          <Text fontWeight="600">View Beneficiary Details</Text>
        </HStack>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Bank name
          </FormLabel>
          <Input defaultValue="Chase bank" w="100%" />
        </FormControl>{" "}
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Account number
          </FormLabel>
          <Input defaultValue="01234567890" w="100%" />
        </FormControl>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Account name
          </FormLabel>
          <Input defaultValue="Write Famous" w="100%" />
        </FormControl>
        <VStack w="85%" py={8} spacing={5}>
          <Button
            variant="outline"
            w="100%"
            size="lg"
            borderRadius={3}
            color="error.300"
            borderColor="error.300"
            onClick={onOpen}
          >
            Remove beneficiary
          </Button>
          <Button variant="secondary" w="100%" size="lg" borderRadius={3}>
            Save
          </Button>
        </VStack>
      </VStack>
    </MobileModalWrapper>
  );
};

const RemoveBeneficiaryModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6}>
            <Center w="2rem" h="2rem" bg="red.100" borderRadius="100%">
              <Box color="red">
                <BsInfoCircle />
              </Box>
            </Center>
            <VStack color="error.300">
              <Heading fontSize="1.3rem" fontWeight="600">
                Remove this beneficiary?
              </Heading>
              <Text w="70%" textAlign="center">
                Are you sure you want to remove this beneficiary and all its
                information?
              </Text>
            </VStack>
            <Flex flexDir="column" w="50%" gap={4}>
              <Button
                w="100%"
                borderColor="error.300"
                variant="outline"
                color="error.300"
                onClick={onClose}
              >
                No, don't
              </Button>
              <Button
                w="100%"
                bg="error.300"
                colorScheme="red"
                onClick={() => navigate("/dashboard/beneficiaries")}
              >
                Yes, remove
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
