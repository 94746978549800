import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Menu,
  MenuButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { BsLink45Deg } from "react-icons/bs";
import { FiChevronDown, FiMail } from "react-icons/fi";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
  MdOutlineIosShare,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import { handleCloseMainModal } from "../../../features/modals/modalSlice";
import ExchangeRate from "./ExchangeRate";
import { AnimatePresence, motion } from "framer-motion";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate("/dashboard")}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [copied, setCopied] = useState(false);

  return step !== 1 ? (
    <MobileModalWrapper>
      <AnimatePresence>
        <motion.div
          initial={{ x: step === 2 ? "-100%" : 0 }}
          animate={{ x: 0 }}
        >
          <VStack w="100%" spacing={5} p={{ base: 5, md: 0 }}>
            <HStack
              as={Button}
              w="fit-content"
              alignSelf="flex-start"
              spacing={3}
              colorScheme="none"
              color="dark.500"
              px={0}
            >
              <Center
                p={2}
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius={5}
                display={{ base: "none", md: "block" }}
                onClick={() => navigate(-1)}
              >
                <MdOutlineArrowBackIosNew />
              </Center>
              <Text fontWeight="600">Request Money</Text>
            </HStack>
            <ExchangeRate title="How much do you need?" />
            <Box pos="relative" pt={3} w="100%">
              <Menu>
                <Text
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Select destination account
                </Text>
                <MenuButton
                  w="100%"
                  as={Button}
                  rightIcon={<FiChevronDown />}
                  bg="transparent"
                  borderWidth="1px"
                  borderColor="gray.300"
                  textAlign="left"
                  h="3rem"
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                >
                  <HStack>
                    <ReactCountryFlag countryCode="US" svg />
                    <Text>US Dollar</Text>
                  </HStack>
                </MenuButton>
              </Menu>
            </Box>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
              >
                Narration (Optional)
              </FormLabel>
              <Input w="100%" />
            </FormControl>
            <Box py={5} w="85%">
              <Button
                onClick={() => setStep(1)}
                variant="secondary"
                w="100%"
                borderRadius={3}
                size="lg"
              >
                <HStack>
                  <Text>Continue</Text>
                  <MdOutlineArrowForwardIos size={14} />
                </HStack>
              </Button>
            </Box>
          </VStack>
        </motion.div>
      </AnimatePresence>
    </MobileModalWrapper>
  ) : (
    <MobileModalWrapper>
      <motion.div initial={{ x: "100%" }} animate={{ x: 0 }}>
        <VStack spacing={10} p={{ base: 5, md: 0 }}>
          <HStack
            as={Button}
            w="fit-content"
            alignSelf="flex-start"
            spacing={3}
            colorScheme="none"
            color="dark.500"
            px={0}
            onClick={() => setStep(2)}
          >
            <Center
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
              borderRadius={5}
              display={{ base: "none", md: "block" }}
            >
              <MdOutlineArrowBackIosNew />
            </Center>
            <Text fontWeight="600">Request Money</Text>
          </HStack>
          <VStack w="100%" spacing={3}>
            <Text fontWeight="600" fontSize="1.1rem">
              Your request is now ready to be shared. How would you like to
              share it?
            </Text>
            <Text>
              The link is only valid for 48 hours. You can also cancel this
              request at any time.
            </Text>
          </VStack>
          {copied ? (
            <Box w="100%">
              <motion.div initial={{ y: "100%" }} animate={{ y: 0 }}>
                <VStack w="100%" spacing={8}>
                  <VStack w="100%" py={5} bg="#F8FAFF" color="brand.purple">
                    <Heading fontWeight="600" fontSize="1.1rem">
                      Link Copied!
                    </Heading>
                    <Text>Your link has been copied.</Text>
                  </VStack>
                  <Button
                    variant="secondary"
                    w="85%"
                    size="lg"
                    onClick={() => navigate("/dashboard")}
                  >
                    Go to dashboard
                  </Button>
                </VStack>
              </motion.div>
            </Box>
          ) : (
            <HStack w="100%" justifyContent="space-between">
              <VStack>
                <Center
                  w="3rem"
                  h="3rem"
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius={5}
                >
                  <FiMail />
                </Center>
                <Text>Email</Text>
              </VStack>
              <VStack onClick={() => setCopied(true)}>
                <Center
                  w="3rem"
                  h="3rem"
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius={5}
                >
                  <BsLink45Deg />
                </Center>
                <Text>Copy</Text>
              </VStack>
              <VStack>
                <Center
                  w="3rem"
                  h="3rem"
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius={5}
                >
                  <MdOutlineIosShare />
                </Center>
                <Text>Share</Text>
              </VStack>
            </HStack>
          )}
        </VStack>
      </motion.div>
    </MobileModalWrapper>
  );
};
