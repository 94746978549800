import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainModalToRender: "",
  isMainModalActive: false,
  isFeedbackModalActive: false,
  isLogoutDialogActive: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    handleToggleLogoutDialog: (state) => {
      state.isLogoutDialogActive = !state.isLogoutDialogActive;
    },
    handleOpenMainModal: (state) => {
      state.isMainModalActive = true;
    },
    handleCloseMainModal: (state) => {
      state.isMainModalActive = false;
    },
    handleMainModalToRender: (state, action) => {
      state.mainModalToRender = action.payload;
    },
    handleOpenFeedbackModal: (state) => {
      state.isFeedbackModalActive = true;
    },
    handleCloseFeedbackModal: (state) => {
      state.isFeedbackModalActive = false;
    },
  },
});

export const {
  handleOpenMainModal,
  handleCloseMainModal,
  handleMainModalToRender,
  handleCloseFeedbackModal,
  handleOpenFeedbackModal,
  handleToggleLogoutDialog,
} = modalSlice.actions;

export default modalSlice.reducer;
