import {
  Button,
  Center,
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdOutlineArrowBackIosNew } from "react-icons/md";
import { useDispatch } from "react-redux";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import {
  handleCloseMainModal,
  handleMainModalToRender,
} from "../../../features/modals/modalSlice";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  return step !== 1 ? (
    <MobileModalWrapper>
      <AnimatePresence>
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ x: step === 2 ? "-100%" : 0 }}
          animate={{ x: 0 }}
        >
          <VStack spacing={5} w="100%" px={{ base: 5, md: 0 }}>
            <HStack w="100%" spacing={3}>
              <Text fontSize="1.1rem" fontWeight="600">
                Notifications
              </Text>
              <Spacer />
              <IconButton
                display={{ base: "none", md: "block" }}
                aria-label="Close"
                variant="outline"
                color="error.300"
                onClick={() => dispatch(handleCloseMainModal())}
              >
                <Center w="100%" h="100%">
                  <MdClose />
                </Center>
              </IconButton>
            </HStack>
            <VStack w="100%">
              <NotificationCard
                setStep={setStep}
                description="Your transfer to Write Famous was successful"
                time="2 minutes ago"
                title="Transfer to Write Famous"
              />
              <NotificationCard
                setStep={setStep}
                description="We have successfully added Write Famous"
                time="30 minutes ago"
                title="Write Famous added as beneficiary"
              />
            </VStack>
          </VStack>
        </motion.div>
      </AnimatePresence>
    </MobileModalWrapper>
  ) : (
    <MobileModalWrapper>
      <motion.div initial={{ x: "100%" }} animate={{ x: 0 }}>
        <VStack spacing={5} py={{ base: 5, md: 0 }} px={{ base: 5, md: 0 }}>
          <HStack w="100%" spacing={3}>
            <Center
              p={2}
              borderWidth="1px"
              borderColor="gray.300"
              borderRadius={5}
              display={{ base: "none", md: "block" }}
              onClick={() => setStep(2)}
            >
              <MdOutlineArrowBackIosNew />
            </Center>
            <Text fontWeight="600">Notifications</Text>
          </HStack>
          <VStack
            color="dark.100"
            spacing={5}
            p={5}
            borderColor="dark.50"
            borderWidth="1px"
            borderRadius={5}
          >
            <Text fontWeight="600" color="dark.500">
              Transaction Information
            </Text>
            <Text color="dark.200" textAlign="center">
              Your transfer to write famous was sent successfully. View details
              below.
            </Text>
            <VStack pt={3} spacing={3} w="100%">
              <Text>Sender information</Text>
              <HStack alignItems="flex-start" w="100%">
                <Text w="50%">Sent from:</Text>
                <Text w="50%">US Dollar wallet</Text>
              </HStack>
              <HStack alignItems="flex-start" w="100%">
                <Text w="50%">Amount:</Text>
                <Text w="50%">1,003.5 USD (including service charge)</Text>
              </HStack>
            </VStack>
            <VStack pt={3} spacing={3} w="100%">
              <Text>Recipient information</Text>
              <HStack alignItems="flex-start" w="100%">
                <Text w="50%">Account number:</Text>
                <Text w="50%">0012345678</Text>
              </HStack>
              <HStack alignItems="flex-start" w="100%">
                <Text w="50%">Account name:</Text>
                <Text w="50%">Write Famous</Text>
              </HStack>
              <HStack alignItems="flex-start" w="100%">
                <Text w="50%">Narration:</Text>
                <Text w="50%">As requested</Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </motion.div>
    </MobileModalWrapper>
  );
};

const NotificationCard = ({
  title,
  description,
  time,
  setStep,
}: {
  title: string;
  description: string;
  time: string;
  setStep: (e: number) => void;
}) => {
  return (
    <HStack
      alignItems="flex-start"
      w="100%"
      borderBottomWidth="1px"
      borderColor="dark.50"
      py={5}
    >
      <VStack alignItems="flex-start" w="100%">
        <Text noOfLines={1} fontWeight="600">
          {title}
        </Text>
        <Text noOfLines={1}>{description}</Text>
        <Button
          color="dark.100"
          variant="link"
          fontSize=".9rem"
          onClick={() => setStep(1)}
        >
          click to view more information
        </Button>
      </VStack>
      <Text color="dark.100" w="10rem" fontSize=".9rem">
        {time}
      </Text>
    </HStack>
  );
};
