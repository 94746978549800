import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import TransferFeedbackModal from "../feedback/TransferFeedbackModal";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate("/dashboard")}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const [type, setType] = useState(0);
  const [success, setSuccess] = useState<boolean | undefined>();

  return (
    <MobileModalWrapper>
      {type !== 1 ? (
        <AnimatePresence>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ x: type === 2 ? "-100%" : 0 }}
            animate={{ x: 0 }}
          >
            <VStack spacing={5} px={{ base: 5, md: 0 }}>
              {success !== undefined ? <TransferFeedbackModal /> : null}
              <HStack w="100%" spacing={3}>
                <Center
                  p={2}
                  borderWidth="1px"
                  borderColor="gray.300"
                  borderRadius={5}
                  display={{ base: "none", md: "block" }}
                  onClick={() => navigate(-1)}
                >
                  <MdOutlineArrowBackIosNew />
                </Center>
                <Text fontWeight="600">Fund Account/Wallet</Text>
              </HStack>
              <Box pos="relative" pt={3} w="100%">
                <Menu>
                  <Text
                    pos="absolute"
                    top={0}
                    left={3}
                    px={2}
                    bg="white"
                    zIndex={20}
                  >
                    Select account to debit
                  </Text>
                  <MenuButton
                    _hover={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                    w="100%"
                    as={Button}
                    rightIcon={<FiChevronDown />}
                    bg="transparent"
                    borderWidth="1px"
                    borderColor="gray.300"
                    textAlign="left"
                    h="3rem"
                  >
                    <HStack>
                      <ReactCountryFlag countryCode="US" svg />
                      <Text>US Dollar Wallet</Text>
                    </HStack>
                  </MenuButton>
                </Menu>
              </Box>
              <Box pos="relative" pt={3} w="100%">
                <Menu>
                  <Text
                    pos="absolute"
                    top={0}
                    left={3}
                    px={2}
                    bg="white"
                    zIndex={20}
                  >
                    Select account to credit
                  </Text>
                  <MenuButton
                    _hover={{ bg: "transparent" }}
                    _focus={{ bg: "transparent" }}
                    w="100%"
                    as={Button}
                    rightIcon={<FiChevronDown />}
                    bg="transparent"
                    borderWidth="1px"
                    borderColor="gray.300"
                    textAlign="left"
                    h="3rem"
                  >
                    <HStack>
                      <ReactCountryFlag countryCode="US" svg />
                      <Text>GB Pounds Wallet</Text>
                    </HStack>
                  </MenuButton>
                </Menu>
              </Box>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Amount to fund
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Narration (Optional)
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <VStack
                w="100%"
                p={2}
                borderWidth="1px"
                borderColor="lightblue.100"
                borderRadius={5}
                spacing={5}
              >
                <Text fontSize=".9rem">Enter your 4-digit PIN</Text>
                <HStack>
                  <PinInput placeholder=" ">
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                    <PinInputField borderColor="lightblue.100" />
                  </PinInput>
                </HStack>
              </VStack>
              <Button
                colorScheme="whatsapp"
                bg="success.300"
                w="85%"
                size="lg"
                borderRadius={3}
                onClick={() => setSuccess(true)}
              >
                Fund account
              </Button>
              <Divider color="dark.400" />
              <Box textAlign="center">
                To fund your account using a card,{" "}
                <Button
                  variant="link"
                  fontWeight="600"
                  color="dark.500"
                  textDecor="underline"
                  onClick={() => setType(1)}
                >
                  Click here
                </Button>
              </Box>
            </VStack>
          </motion.div>
        </AnimatePresence>
      ) : (
        <motion.div initial={{ x: "100%" }} animate={{ x: 0 }}>
          <VStack spacing={5} px={{ base: 5, md: 0 }}>
            {success !== undefined ? <TransferFeedbackModal /> : null}
            <HStack w="100%" spacing={3}>
              <Center
                p={2}
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius={5}
                display={{ base: "none", md: "block" }}
                onClick={() => setType(2)}
              >
                <MdOutlineArrowBackIosNew />
              </Center>
              <Text fontWeight="600">Fund Account With Card</Text>
            </HStack>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
              >
                Card number
              </FormLabel>
              <Input w="100%" />
            </FormControl>
            <HStack>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Expiry date
                </FormLabel>
                <Input w="100%" />
              </FormControl>
              <FormControl pos="relative" w="100%" pt={3.5}>
                <FormLabel
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  CVV
                </FormLabel>
                <Input w="100%" />
              </FormControl>
            </HStack>
            <Box pos="relative" pt={3} w="100%">
              <Menu>
                <Text
                  pos="absolute"
                  top={0}
                  left={3}
                  px={2}
                  bg="white"
                  zIndex={20}
                >
                  Select account to credit
                </Text>
                <MenuButton
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  w="100%"
                  as={Button}
                  rightIcon={<FiChevronDown />}
                  bg="transparent"
                  borderWidth="1px"
                  borderColor="gray.300"
                  textAlign="left"
                  h="3rem"
                >
                  <HStack>
                    <ReactCountryFlag countryCode="US" svg />
                    <Text>US Dollar Wallet</Text>
                  </HStack>
                </MenuButton>
              </Menu>
            </Box>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
              >
                Amount to fund
              </FormLabel>
              <Input w="100%" />
            </FormControl>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
              >
                Narration (Optional)
              </FormLabel>
              <Input w="100%" />
            </FormControl>
            <Box py={5} w="80%">
              <Button
                colorScheme="whatsapp"
                bg="success.300"
                size="lg"
                w="100%"
                onClick={() => setSuccess(true)}
              >
                Fund account
              </Button>
            </Box>
            <Divider color="dark.400" />
            <Box textAlign="center">
              To fund your account using your Salvapay wallet,{" "}
              <Button
                variant="link"
                fontWeight="600"
                textDecor="underline"
                color="dark.500"
                onClick={() => setType(2)}
              >
                Click here
              </Button>
            </Box>
          </VStack>
        </motion.div>
      )}
    </MobileModalWrapper>
  );
};
