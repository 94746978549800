import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Menu, MenuButton } from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";

export default ({ title }: { title: string }) => {
    return (
      <FormControl
        pt={5}
        w="100%"
        overflowX="hidden"
        borderRadius={3}
        pos="relative"
      >
        <FormLabel
          pos="absolute"
          top="7px"
          left="7px"
          zIndex={20}
          bg="white"
          px={2}
        >
          {title}
        </FormLabel>
        <InputGroup>
          <Input pos="relative" defaultValue="1000" size="lg" />
          <InputRightElement
            h="100%"
            w="fit-content"
            bg="brand.purple"
            color="white"
          >
            <Menu>
              <MenuButton
                as={Button}
                variant="secondary"
                color="white"
                rightIcon={<FiChevronDown />}
                leftIcon={<ReactCountryFlag countryCode="US" svg />}
                borderRadius={5}
                h="100%"
              >
                USD
              </MenuButton>
            </Menu>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    );
  };