import { ChakraProvider } from "@chakra-ui/react";
import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { theme } from "./chakra";
import authReducer from "./features/auth/authSlice";
import headerReducer from "./features/header/headerSlice";
import modalReducer from "./features/modals/modalSlice";
import pageReducer from "./features/page/pageSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    header: headerReducer,
    page: pageReducer,
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
