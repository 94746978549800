import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PinInput,
  PinInputField,
  Radio,
  Select,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import Header from "../../components/Header";
import Stepper from "../../components/Stepper";
import { ChangeEvent, createContext, useContext, useState } from "react";
import { FiChevronDown, FiEye, FiEyeOff, FiUploadCloud } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";

export const StepperContext = createContext<any>(null);

export default () => {
  const [step, setStepper] = useState(1);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordVisible = () => setPasswordVisible(!passwordVisible);

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(e.target.value);

  const [mobile] = useMediaQuery("(max-width: 480px)");

  const handleNext = () => setStepper(step + 1);

  return (
    <StepperContext.Provider
      value={{
        step,
        handleNext,
        handlePassword,
        handleConfirmPassword,
        handlePasswordVisible,
        passwordVisible,
      }}
    >
      {mobile ? <Header /> : null}
      <Flex
        flexFlow={{ base: "wrap column-reverse", md: "nowrap" }}
        pt={{ base: 10, md: 0 }}
      >
        <Flex justifyContent="center" w={{ base: "100%", md: "75%" }} py={16}>
          <VStack w="100%" maxW="54rem" px={{ base: 6, md: 0 }} spacing={8}>
            <Image
              src="/salvapay-icon.svg"
              display={{ base: "none", md: "block" }}
            />
            <Heading
              fontSize="1.5rem"
              fontWeight="600"
              textAlign={{ base: "left", md: "center" }}
              maxW="md"
            >
              Follow the steps below to create your salvapay account
            </Heading>
            <Stepper />
            <ReturnStep />
          </VStack>
        </Flex>
        <Box
          bgImage="/auth-right-image.png"
          bgPos="center"
          minH={{ base: "20rem", md: "100vh" }}
          w={{ base: "100%", md: "25%" }}
        />
      </Flex>
    </StepperContext.Provider>
  );
};

const ReturnStep = () => {
  const { step } = useContext(StepperContext);

  switch (step) {
    case 1:
      return <FirstStep />;
    case 2:
      return <SecondStep />;
    case 3:
      return <ThirdStep />;
    case 4:
      return <FourthStep />;
    case 5:
      return <FifthStep />;
    case 6:
      return <SixthStep />;
    default:
      return <>Done</>;
  }
};

const FirstStep = () => {
  const { handleNext, step } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1.5rem"
        fontWeight="600"
        alignSelf={{ base: "flex-start", md: "center" }}
      >
        Enter a functional email address
      </Heading>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Email address
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <Button
        colorScheme="purple"
        bg="brand.purple"
        w="80%"
        h="2.8rem"
        onClick={handleNext}
      >
        Continue
      </Button>
      <Link href="/login" textDecor="underline">
        I am an existing user
      </Link>
    </VStack>
  );
};

const SecondStep = () => {
  const { handleNext, step } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1.2rem"
        fontWeight="600"
        alignSelf={{ base: "flex-start", md: "center" }}
      >
        Choose your type of account
      </Heading>
      <HStack
        onClick={handleNext}
        w={{ base: "100%", md: "sm" }}
        borderWidth="1px"
        borderRadius={5}
        borderColor="gray.300"
        p={5}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <VStack alignItems="left" w="100%">
          <Heading fontSize="1rem" fontWeight="500">
            Individual account
          </Heading>
          <Text>Send money to anyone, anywhere hassle-free and quickly</Text>
        </VStack>
        <Radio />
      </HStack>
      <HStack
        onClick={handleNext}
        w={{ base: "100%", md: "sm" }}
        borderWidth="1px"
        borderRadius={5}
        borderColor="gray.300"
        p={5}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <VStack alignItems="left" w="100%">
          <Heading fontSize="1rem" fontWeight="500">
            Business account
          </Heading>
          <Text>Pay partners and supplies. Fast, safe and easy.</Text>
        </VStack>
        <Radio />
      </HStack>
    </VStack>
  );
};

const ThirdStep = () => {
  const { handleNext, step } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1.2rem"
        fontWeight="600"
        alignSelf={{ base: "flex-start", md: "center" }}
      >
        Enter your information below
      </Heading>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Full name
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Phone
        </FormLabel>
        <InputGroup>
          <InputLeftElement>+1</InputLeftElement>
          <Input w="100%" />
        </InputGroup>
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Country of residence
        </FormLabel>
        <Menu>
          <MenuButton
            w="100%"
            as={Button}
            rightIcon={<FiChevronDown />}
            bg="transparent"
            borderWidth="1px"
            borderColor="gray.300"
            textAlign="left"
          >
            <HStack>
              <ReactCountryFlag countryCode="US" svg />
              <Text>United States</Text>
            </HStack>
          </MenuButton>
          <MenuList zIndex={30}>
            {Array.from("123456789").map((_, index) => {
              return (
                <MenuItem key={index}>
                  <HStack>
                    <ReactCountryFlag countryCode="US" svg />
                    <Text>United States</Text>
                  </HStack>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </FormControl>
      <Button
        colorScheme="purple"
        bg="brand.purple"
        w="80%"
        h="2.8rem"
        onClick={handleNext}
      >
        Continue
      </Button>
    </VStack>
  );
};

const FourthStep = () => {
  const {
    handleNext,
    handlePassword,
    handlePasswordVisible,
    handleConfirmPassword,
    passwordVisible,
    step,
  } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1.2rem"
        fontWeight="600"
        alignSelf={{ base: "flex-start", md: "center" }}
      >
        Add a password
      </Heading>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Password
        </FormLabel>
        <InputGroup>
          <Input
            w="100%"
            onChange={handlePassword}
            type={passwordVisible ? "text" : "password"}
          />
          <InputRightElement>
            {passwordVisible ? (
              <IconButton
                icon={<FiEyeOff />}
                aria-label="Password visible"
                bg="transparent"
                onClick={handlePasswordVisible}
              />
            ) : (
              <IconButton
                icon={<FiEye />}
                aria-label="Password visible"
                bg="transparent"
                onClick={handlePasswordVisible}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Retype Password
        </FormLabel>
        <InputGroup>
          <Input
            w="100%"
            onChange={handleConfirmPassword}
            type={passwordVisible ? "text" : "password"}
          />
          <InputRightElement>
            {passwordVisible ? (
              <IconButton
                icon={<FiEyeOff />}
                aria-label="Password visible"
                bg="transparent"
                onClick={handlePasswordVisible}
              />
            ) : (
              <IconButton
                icon={<FiEye />}
                aria-label="Password visible"
                bg="transparent"
                onClick={handlePasswordVisible}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        colorScheme="purple"
        bg="brand.purple"
        w="80%"
        h="2.8rem"
        onClick={handleNext}
      >
        Continue
      </Button>
    </VStack>
  );
};

const FifthStep = () => {
  const {
    handleNext,
    handlePassword,
    handlePasswordVisible,
    handleConfirmPassword,
    passwordVisible,
    step,
  } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1rem"
        fontWeight="500"
        alignSelf={{ base: "flex-start", md: "center" }}
        textAlign="center"
      >
        To ensure this is you, we have sent a 6-digit code to your email
        address. Kindly inut the codes below and continue.
      </Heading>
      <HStack>
        <PinInput size="lg" placeholder=" ">
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Button
        colorScheme="purple"
        bg="brand.purple"
        w="80%"
        h="2.8rem"
        onClick={handleNext}
      >
        Continue
      </Button>
      <HStack>
        <Text>Didn't receive any code?</Text>
        <Text textDecor="underline" color="brand.purple">
          Resend
        </Text>
      </HStack>
    </VStack>
  );
};

const SixthStep = () => {
  const {
    handleNext,
    handlePassword,
    handlePasswordVisible,
    handleConfirmPassword,
    passwordVisible,
    step,
  } = useContext(StepperContext);

  return (
    <VStack w="100%" maxW="sm" spacing={8}>
      <Heading
        fontSize="1rem"
        fontWeight="500"
        alignSelf={{ base: "flex-start", md: "center" }}
        textAlign="center"
      >
        Let us identify you
      </Heading>
      <VStack borderWidth="1px" borderColor="gray.300" w="100%" py={5}>
        <Flex p={2} bg="gray.200" borderRadius="100%">
          <FiUploadCloud />
        </Flex>
        <Heading fontSize="1.1rem" fontWeight="500">
          Upload a valid means of ID
        </Heading>
        <Heading color="blackAlpha.600" fontSize="1rem" fontWeight="500">
          SVG, PNG, JPG
        </Heading>
      </VStack>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          State of residence
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Zip code
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          Residential address
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <FormControl pos="relative" w="100%" pt={3.5}>
        <FormLabel
          pos="absolute"
          top={0}
          left={3}
          px={2}
          bg="white"
          zIndex={20}
        >
          ID card number
        </FormLabel>
        <Input w="100%" />
      </FormControl>
      <Button
        colorScheme="purple"
        bg="brand.purple"
        w="80%"
        h="2.8rem"
        onClick={handleNext}
        _disabled={{ bg: "brand.gray", color: "white" }}
        disabled
      >
        Continue
      </Button>
    </VStack>
  );
};
