import { Button, HStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default () => {
  const [location, setLocation] = useState(window.location.pathname);

  return (
    <HStack
      borderBottomWidth="1px"
      borderColor="dark.50"
      display={{ base: "none", md: "flex" }}
    >
      <Tab
        action={setLocation}
        link="/dashboard/settings"
        title="Profile setings"
        active={location === "/dashboard/settings"}
      />
      <Tab
        action={setLocation}
        link="/dashboard/settings/kyc"
        title="Update KYC"
        active={location.includes("kyc")}
      />
      <Tab
        action={setLocation}
        link="/dashboard/settings/security"
        title="Security settings"
        active={location.includes("security")}
      />
      <Tab
        action={setLocation}
        link="/dashboard/settings/card"
        title="Card settings"
        active={location.includes("card")}
      />
      <Tab
        action={setLocation}
        link="/dashboard/settings/contact"
        title="Contact us"
        active={location.includes("contact")}
      />
    </HStack>
  );
};

const Tab = ({ action, active, link, title }: any) => {
  const navigate = useNavigate();

  return (
    <Button
      bg={active ? "dark.50" : "transparent"}
      color={active ? "dark.500" : "light.500"}
      borderBottomWidth={active ? "2px" : "0"}
      borderColor={active ? "dark.500" : ""}
      onClick={() => {
        action(link);
        navigate(link);
      }}
      borderBottomRadius={0}
      _hover={{
        borderBottomWidth: "2px",
        borderColor: "dark.500",
        bg: "dark.50",
        color: "dark.500",
      }}
    >
      {title}
    </Button>
  );
};
