import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      "*": {
        "::-webkit-scrollbar": { width: "5px" },
        "::-webkit-scrollbar-track": { background: "#f0f5ff" },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#6599fe",
          borderRadius: "30px",
        },
      },
    },
  },
  colors: {
    brand: {
      purple: "#020145",
      gray: "#e7e7e7",
    },
    darkblue: {
      50: "#e6e6ec",
      75: "#9797b3",
      100: "#6c6c93",
      200: "#2d2c65",
      300: "#020145",
      400: "#010130",
      500: "#01012a",
    },
    lightblue: {
      50: "#f0f5ff",
      75: "#c0d5ff",
      100: "#a6c4fe",
      200: "#7faafe",
      300: "#6599fe",
      400: "#476bb2",
      500: "#3e5d9b",
    },
    dark: {
      50: "#e7e7e7",
      75: "#9e9e9e",
      100: "#767676",
      200: "#3a3a3a",
      300: "#121212",
      400: "#0d0d0d",
      500: "#0b0b0b",
    },
    light: {
      50: "#ffffff",
      75: "#ffffff",
      100: "#ffffff",
      200: "#ffffff",
      300: "#ffffff",
      400: "#b3b3b3",
      500: "#9c9c9c",
    },
    error: {
      50: "#fbe9ea",
      75: "#f0a3a7",
      100: "#ea7d82",
      200: "#e0444d",
      300: "#da1e28",
      400: "#99151c",
      500: "#851218",
    },
    success: {
      50: "#e6f2ed",
      75: "#97c8b4",
      100: "#6cb295",
      200: "#2d9167",
      300: "#027a48",
      400: "#015532",
      500: "#014a2c",
    },
  },
  fonts: {
    // heading: "Bozon",
    // body: "Bozon",
  },
  components: {
    Drawer: {
      sizes: {
        secondary: {
          dialog: {
            maxWidth: "25rem",
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          backgroundColor: "#6599fe",
          color: "#6599fe",
          _hover: {
            backgroundColor: "#7faafe",
          },
        },
        secondary: {
          backgroundColor: "#020145",
          color: "#ffffff",
          _hover: {
            backgroundColor: "#6c6c93",
          },
        },
      },
    },
  },
});
