import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MobileModalWrapper from "../../../components/MobileModalWrapper";
import BeneficiaryFeedbackModal from "../feedback/BeneficiaryFeedbackModal";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        isOpen={true}
        placement="right"
        onClose={() => navigate(-1)}
        size={{ base: "full", md: "secondary" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            <Main />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Main = () => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(true);
  const [active, setActive] = useState<boolean>(false);

  return (
    <MobileModalWrapper>
      <VStack spacing={5} py={{ base: 5, md: 0 }} px={{ base: 5, md: 0 }}>
        {active ? <BeneficiaryFeedbackModal success={success} /> : null}
        <HStack w="100%" spacing={3}>
          <Center
            p={2}
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius={5}
            display={{ base: "none", md: "block" }}
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBackIosNew />
          </Center>
          <Text fontWeight="600">Add Beneficiary</Text>
        </HStack>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Bank name
          </FormLabel>
          <Input w="100%" />
        </FormControl>{" "}
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Account number
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
          >
            Account name
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <Button
          variant="secondary"
          w="85%"
          size="lg"
          borderRadius={3}
          onClick={() => {
            setActive(true);
          }}
        >
          Add beneficiary
        </Button>
      </VStack>
    </MobileModalWrapper>
  );
};
