import { Flex, Heading, Text, VStack } from "@chakra-ui/react";
import Header from "../components/Header";
import { Footer } from "./Home";

const faqs = [
  {
    q: "Who can use SalvaPay?",
    a: "Salvapay is for everyone who meets the regulated criteria for creating an account and using the platform. Please refer to our terms and conditions.",
  },
  {
    q: "Must I download the app?",
    a: "One of the beautiful things about Salvapay is that you can use it on the go without having to download it on your mobile phone or computer.",
  },
  {
    q: "Is SalvaPay available in my country?",
    a: "Salvapay currently operates in a number of selected countries and we are expanding fast. Please look out for your country in the dropdown and/or the footer to ascertain if your country is among them.",
  },
  {
    q: "How much is the maximum amount I can send?",
    a: "The amount you can send is determined by your bank and local government’s regulations. Our aim is to enable you to move money for business and personal use in compliance with your local governments’ policies.",
  },
  {
    q: "What's the maximum amount I can receive?",
    a: "As stated above, this depends on the policies of your bank(s) and government regulations.",
  },
  {
    q: "What if I can't remember my password/username?",
    a: "It is easy and straightforward to recover any credentials on Salvapay, but we will need to verify that you are the right person.",
  },
  {
    q: "How often can I send money?",
    a: "As often as you may. Also, check with your bank(s) and government policies.",
  },
  {
    q: "Do I need to visit the bank before sending money?",
    a: "NO. You don’t need to visit any bank. You can send money directly using your card or send it even fast from your Salvapay wallet after funding it.",
  },
  {
    q: "Can I make a payment with SalvaPay?",
    a: "Sure. But in the meantime, you can only send money across the world. We are working around the clock to enable you to make all kinds of payments via Salvapy. Look out.",
  },
  {
    q: "Can I exit if I want to discontinue using SalvaPay?",
    a: "We hope to serve you so well that you won’t want to go. But if for any reason, you have to exit, it’s very simple, you will simply disable/terminate your account.",
  },
];

export default () => {
  return (
    <>
      <Header />
      <VStack
        w="100%"
        maxW="80rem"
        mx="auto"
        px={{ base: 5, md: 14 }}
        py={8}
        spacing={6}
      >
        <Text fontWeight="600" color="purple.900">
          FAQs
        </Text>
        <Heading fontSize="1.8rem" fontWeight="600" color="purple.900" textAlign="center">
          Frequently asked questions
        </Heading>
        <Text>Have questions? We're here to help</Text>
        <Flex
          wrap="wrap"
          justifyContent="space-between"
          py={5}
          rowGap={8}
          columnGap={2}
        >
          {faqs.map(({ q, a }) => (
            <QuestionCard question={q} answer={a} />
          ))}
        </Flex>
      </VStack>
      <Footer />
    </>
  );
};

const QuestionCard = ({ question, answer }: QuestionCard) => {
  return (
    <VStack w={{ base: "100%", md: "sm" }}>
      <Text fontWeight="600" w="100%">
        {question}
      </Text>
      <Text color="blackAlpha.700">{answer}</Text>
    </VStack>
  );
};
