import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: window.location.pathname,
};

export const linkSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { handleCurrentPage } = linkSlice.actions;

export default linkSlice.reducer;
